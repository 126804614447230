import { template as template_7806fd1a816347788d568dab2cda2a75 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7806fd1a816347788d568dab2cda2a75(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
