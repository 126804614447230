import { template as template_34dc8d6bf1264d95ad0f90a3f2bbecf5 } from "@ember/template-compiler";
const FKLabel = template_34dc8d6bf1264d95ad0f90a3f2bbecf5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
